label {
	display: block;
	margin-top: 10px;
}

.card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
}

.card {
	background-color: #f7f7f7;
	padding: 7px 8px 8px;
	margin: 0 auto 0px;
	margin-top: 25px;
	border-radius: 2px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.timeline {
	border-left: 3px solid #727cf5;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
	background: rgba(114, 124, 245, 0.09);
	letter-spacing: 0.2px;
	position: relative;
	line-height: 1.4em;
	font-size: 1.03em;
	padding: 25px;
	list-style: none;
	text-align: left;
	max-width: 100%;
}

.timeline h1 {
	font-weight: 300;
	font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
	font-weight: 600;
	font-size: 1rem;
	margin-bottom: 10px;
}

.timeline .event {
	border-bottom: 1px dashed #e8ebf1;
	padding-bottom: 25px;
	margin-bottom: 25px;
	padding-top: 30px;
	position: relative;
}

.timeline .event:last-of-type {
	padding-bottom: 0;
	margin-bottom: 0;
	border: none;
}

.timeline .event:before,
.timeline .event:after {
	position: absolute;
	display: block;
	top: 0;
}

.timeline .event:before {
	left: 0px;
	content: attr(data-date);
	text-align: left;
	font-weight: 100;
	font-size: 0.9em;
	min-width: 120px;
}

.timeline .event:after {
	box-shadow: 0 0 0 3px #727cf5;
	left: -31.8px;
	background: #fff;
	border-radius: 50%;
	height: 9px;
	width: 9px;
	content: "";
	top: 5px;
}

.rtl .timeline {
	border-left: 0;
	text-align: right;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
	left: 0;
	right: -170px;
}

.rtl .timeline .event::after {
	left: 0;
	right: -55.8px;
}

.govgr-grid-column-third-thirds {
	grid-column-start: span 3;
	grid-column-end: span 3;
}

.MuiToggleButton-root{
	text-transform: unset !important;
}

.govgr-footer__section-break_tweak {
  /* margin: auto auto 1.25rem; */
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(204, 204, 204, var(--tw-border-opacity));
  /* padding: .5rem 1rem; */
}
